import styled from 'styled-components'
import { colors, fontWeight } from '../../styled/variables'

export const Wrapper = styled.div`
	width: 100%;
	margin: 40px;
`

export const Title = styled.h1`
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	font-size: 2rem;
	margin: 0;
	text-align: center;
`
