import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'

import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'

import setMinutes from 'date-fns/setMinutes'
import getTime from 'date-fns/getTime'

import {
	Form,
	FormItem,
	FormLabel,
	FormInput,
	ErrorMessage,
	FormTextArea,
	Button,
} from '../../components/Form/styled'
import TitlePage from '../../components/TitlePage'
import { container, Event } from '../../services/firebase'
import * as texts from '../../texts/index.json'
import { Wrapper } from './styled'

type FormData = {
	name: string
	description: string
	speakerName: string
	speakerDescription: string
	price: string
	place: string
}

const EditCourse: React.FC = () => {
	const [event, setEvent] = useState<Event | null>()
	const { register, handleSubmit, errors, reset } = useForm()

	const history = useHistory()

	const { id } = useParams<{ id: string }>()

	const initialDate = setMinutes(new Date(), 0)

	const [start, setStart] = useState<number>(getTime(initialDate))
	const [end, setEnd] = useState<number>(getTime(initialDate))

	const [startPickerDate, setStartPicker] = useState(initialDate)
	const [endPickerDate, setEndPicker] = useState(initialDate)

	const [formData, setFormData] = useState<FormData>()

	const [rangePickerError, setRangePickerError] = useState<boolean>(false)

	const useStyles = makeStyles({
		root: {
			width: '100%',
			borderRadius: '12px',
			outline: 'none',
			border: '1px solid #017723',
			fontSize: '1rem',
			backgroundColor: '#ffffff',
		},
	})

	const saveTemporaryData = (partialFormData: Partial<FormData>) => {
		if (!formData) return

		setFormData({ ...formData, ...partialFormData })
	}

	const classes = useStyles()

	useEffect(() => {
		container.collections.events.getEvent(id).then((event) => {
			setEvent(event)
			if (event) {
				setStartPicker(new Date(event.from))
				setEndPicker(new Date(event.to))

				setStart(event.from)
				setEnd(event.to)

				setFormData(event)
			}
		})
	}, [id])

	const onSubmit = async ({
		description,
		name,
		speakerDescription,
		speakerName,
		price,
		place,
	}: FormData) => {
		if (!start || !end) {
			setRangePickerError(true)

			return
		}

		if (end < start) {
			setRangePickerError(true)

			return
		}

		if (!event) return

		try {
			await container.collections.events.editEvent({
				id: event.id,
				description,
				from: start,
				name,
				to: end,
				speakerName,
				speakerDescription,
				price,
				place,
			})

			reset()
			history.push('/administrace')
		} catch (error) {
			console.log(error)
		}
	}

	const nameCourseError = `${texts.pages.editCourse.formNameCourse.error}`
	const placeError = `${texts.pages.editCourse.formPlace.error}`
	const courseInfoError = `${texts.pages.editCourse.formCourseInfo.error}`
	const nameTeacherError = `${texts.pages.editCourse.formNameTeacher.error}`
	const teacherInfoError = `${texts.pages.editCourse.formTeacherInfo.error}`
	const priceError = `${texts.pages.editCourse.formPrice.error}`

	if (formData) {
		return (
			<Wrapper>
				<TitlePage name={texts.pages.editCourse.title} />
				<Form onSubmit={handleSubmit(onSubmit)}>
					{/*------------------NAME-COURSE-----------------*/}
					<FormItem>
						<FormLabel htmlFor="name">
							{texts.pages.editCourse.formNameCourse.name}
						</FormLabel>
						<FormInput
							id="name"
							name="name"
							type="text"
							value={formData.name}
							onChange={(e) =>
								saveTemporaryData({ name: e.target.value })
							}
							placeholder={
								texts.pages.editCourse.formNameCourse
									.placeholder
							}
							ref={register({
								required: nameCourseError,
							})}
						/>
						{errors.name && (
							<ErrorMessage>{errors.name.message}</ErrorMessage>
						)}
					</FormItem>
					{/*------------------PLACE-COURSE-----------------*/}
					<FormItem>
						<FormLabel htmlFor="place">
							{texts.pages.editCourse.formPlace.name}
						</FormLabel>
						<FormInput
							id="place"
							name="place"
							type="text"
							value={formData.place}
							onChange={(e) =>
								saveTemporaryData({ place: e.target.value })
							}
							placeholder={
								texts.pages.editCourse.formPlace.placeholder
							}
							ref={register({
								required: placeError,
							})}
						/>
						{errors.place && (
							<ErrorMessage>{errors.place.message}</ErrorMessage>
						)}
					</FormItem>
					{/*------------------START-MUI-----------------*/}

					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						className={classes.root}
					>
						<FormItem>
							<FormLabel htmlFor="from">
								{texts.pages.editCourse.startTime.name}
							</FormLabel>
							<DateTimePicker
								renderInput={(props) => (
									<TextField
										{...props}
										className={classes.root}
									/>
								)}
								value={startPickerDate}
								ampm={false}
								inputFormat="dd.MM HH:mm"
								views={['year', 'month', 'day', 'hours']}
								className={classes.root}
								onChange={(date: Date | null) => {
									if (!date) {
										return
									}

									const unificationTime = setMinutes(date, 0)

									setStart(getTime(unificationTime))
									setStartPicker(unificationTime)
								}}
							/>
						</FormItem>

						<FormItem>
							<FormLabel htmlFor="to">
								{texts.pages.editCourse.endTime.name}
							</FormLabel>
							<DateTimePicker
								renderInput={(props) => (
									<TextField
										{...props}
										className={classes.root}
									/>
								)}
								value={endPickerDate}
								ampm={false}
								inputFormat="dd.MM HH:mm"
								views={['year', 'month', 'day', 'hours']}
								className={classes.root}
								onChange={(date: Date | null) => {
									if (!date) {
										return
									}

									const unificationTime = setMinutes(date, 0)

									setEnd(getTime(unificationTime))
									setEndPicker(unificationTime)
								}}
							/>
						</FormItem>
					</LocalizationProvider>
					{rangePickerError && (
						<ErrorMessage>
							{texts.pages.editCourse.startTime.error}
						</ErrorMessage>
					)}
					{/*------------------COURSE-INFO------------------*/}
					<FormItem>
						<FormLabel htmlFor="description">
							{texts.pages.editCourse.formCourseInfo.name}
						</FormLabel>
						<FormTextArea
							id="description"
							name="description"
							placeholder={
								texts.pages.editCourse.formCourseInfo
									.placeholder
							}
							ref={register({
								required: courseInfoError,
							})}
							value={formData.description}
							onChange={(e) =>
								saveTemporaryData({
									description: e.target.value,
								})
							}
						/>
						{errors.description && (
							<ErrorMessage>
								{errors.description.message}
							</ErrorMessage>
						)}
					</FormItem>
					{/*------------------NAME-TEACHER-----------------*/}
					<FormItem>
						<FormLabel htmlFor="speakerName">
							{texts.pages.editCourse.formNameTeacher.name}
						</FormLabel>
						<FormInput
							id="speakerName"
							name="speakerName"
							type="text"
							placeholder={
								texts.pages.editCourse.formNameTeacher
									.placeholder
							}
							ref={register({
								required: nameTeacherError,
							})}
							value={formData.speakerName}
							onChange={(e) =>
								saveTemporaryData({
									speakerName: e.target.value,
								})
							}
						/>
						{errors.speakerName && (
							<ErrorMessage>
								{errors.speakerName.message}
							</ErrorMessage>
						)}
					</FormItem>
					{/*------------------TEACHER-INFO------------------*/}
					<FormItem>
						<FormLabel htmlFor="speakerDescription">
							{texts.pages.editCourse.formTeacherInfo.name}
						</FormLabel>
						<FormTextArea
							id="speakerDescription"
							name="speakerDescription"
							placeholder={
								texts.pages.editCourse.formTeacherInfo
									.placeholder
							}
							ref={register({
								required: teacherInfoError,
							})}
							value={formData.speakerDescription}
							onChange={(e) =>
								saveTemporaryData({
									speakerDescription: e.target.value,
								})
							}
						/>
						{errors.speakerDescription && (
							<ErrorMessage>
								{errors.speakerDescription.message}
							</ErrorMessage>
						)}
					</FormItem>
					{/*------------------PRICE-----------------*/}
					<FormItem>
						<FormLabel htmlFor="price">
							{texts.pages.editCourse.formPrice.name}
						</FormLabel>
						<FormInput
							id="price"
							name="price"
							type="text"
							placeholder={
								texts.pages.editCourse.formPrice.placeholder
							}
							ref={register({
								required: priceError,
							})}
							value={formData.price}
							onChange={(e) =>
								saveTemporaryData({ price: e.target.value })
							}
						/>
						{errors.price && (
							<ErrorMessage>{errors.price.message}</ErrorMessage>
						)}
					</FormItem>
					<Button type="submit">
						{texts.pages.editCourse.button}
					</Button>
				</Form>
			</Wrapper>
		)
	}

	return <p></p>
}

export default EditCourse
