import React from 'react'
import Nav from './Nav'
import TopBar from './TopBar'
import { Wrapper } from './styled'

const NavBar: React.FC = () => (
	<>
		<Wrapper>
			<TopBar />
			<Nav />
		</Wrapper>
	</>
)

export default NavBar
