// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
	collection,
	DocumentData,
	getDocs,
	getDoc,
	doc,
	getFirestore,
	addDoc,
	updateDoc,
	deleteDoc,
	DocumentReference,
} from 'firebase/firestore'
import {
	Auth,
	getAuth,
	signInWithEmailAndPassword,
	UserCredential,
	signOut as signOutFirebase,
} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app)

// Authentication
const signInFirebase = (auth: Auth) => (
	email: string,
	password: string
): Promise<UserCredential> => signInWithEmailAndPassword(auth, email, password)

const signtOutFirebase = (auth: Auth) => (): Promise<void> =>
	signOutFirebase(auth)
const auth = getAuth()

// Own types

export type Event = {
	id: string
	name: string
	description: string
	speakerName: string
	speakerDescription: string
	from: number
	to: number
	price: string
	place: string
}

export type EventWithoutId = Omit<Event, 'id'>

// Define collections:
enum CollectionNames {
	Events = 'events',
}

const eventCollection = collection(firestore, CollectionNames.Events)

export const container = {
	authentication: {
		auth,
		signIn: signInFirebase(auth),
		signOut: signtOutFirebase(auth),
	},
	collections: {
		events: {
			getAllEvents: async (): Promise<Event[]> => {
				try {
					const eventDosc = await getDocs(eventCollection)

					return eventDosc.docs.map(
						(doc) => ({ ...doc.data(), id: doc.id } as Event)
					)
				} catch (error) {
					console.error(
						'Firestore failed to deliver event collection:',
						error
					)

					return []
				}
			},
			getEvent: async (idEvent: Event['id']): Promise<Event | null> => {
				try {
					const docRef = doc(
						firestore,
						CollectionNames.Events,
						idEvent
					)

					const document = await getDoc(docRef)
					const event = {
						...document.data(),
						id: document.id,
					} as Event

					return event
				} catch (error) {
					console.error(
						`Firestore failed to deliver event with id: ${idEvent}`,
						error
					)

					return null
				}
			},
			addNewEvent: async (
				event: EventWithoutId
			): Promise<DocumentReference<DocumentData>> =>
				addDoc<DocumentData>(eventCollection, event),
			editEvent: async (event: Event): Promise<boolean> => {
				try {
					const docRef = doc(
						firestore,
						CollectionNames.Events,
						event.id
					)

					await updateDoc(docRef, event)

					return true
				} catch (error) {
					console.error(
						`Firestore failed when update event with id: ${event.id}`,
						error
					)

					return false
				}
			},
			deleteEvent: async (idEvent: Event['id']): Promise<boolean> => {
				try {
					const docRef = doc(
						firestore,
						CollectionNames.Events,
						idEvent
					)

					await deleteDoc(docRef)

					return true
				} catch (error) {
					console.error(
						`Firestore failed when delete event with id: ${idEvent}`,
						error
					)

					return false
				}
			},
		},
	},
}
