import React from 'react'
import TitlePage from '../../components/TitlePage'
import * as texts from '../../texts/index.json'
import { Wrapper } from './styled'

const ReservationSuccess: React.FC = () => {
	return (
		<Wrapper>
			<TitlePage name={texts.pages.successSend.reservation.title} />
			<p>{texts.pages.successSend.reservation.subtitle}</p>
		</Wrapper>
	)
}

export default ReservationSuccess
