import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { breakPoint, colors, widthPages } from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}

	form {
		width: 70%;

		@media (max-width: ${breakPoint.tablet}) {
			width: 85%;
		}
	}

	button {
		margin-top: 20px;
	}
`

export const StyledLink = styled(Link)`
	color: ${colors.text.green};

	&:hover {
		filter: brightness(140%);
	}
`
