import React from 'react'
import { container } from '../../services/firebase'
import { useUser } from '../../features/User'
import { Login, Logout, StyledText, Wrapper } from './styled'
import * as texts from '../../texts/index.json'
import { useHistory } from 'react-router-dom'

const Footer: React.FC = () => {
	const actualYear = new Date().getFullYear()

	const { isLoggedIn, updateUser } = useUser()
	const history = useHistory()

	const logOut = () => {
		container.authentication.signOut()
		updateUser(null)
		history.push('/')
	}

	return (
		<Wrapper>
			<StyledText>{texts.general.company}</StyledText>
			{isLoggedIn ? (
				<Logout onClick={logOut}>{texts.footer.logout}</Logout>
			) : (
				<Login to="/prihlaseni">{texts.footer.login}</Login>
			)}
			{isLoggedIn ? (
				<Login to="/administrace">{texts.footer.administration}</Login>
			) : null}
			<Login to="/podminky">{texts.footer.terms}</Login>
			<StyledText>
				{texts.footer.copyright} &#169; {actualYear}
			</StyledText>
		</Wrapper>
	)
}

export default Footer
