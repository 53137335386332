import styled from 'styled-components'
import { breakPoint, colors, widthPages } from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	margin: 70px 0 260px 0;
	align-items: center;

	p {
		color: ${colors.text.grey};
	}

	@media (max-width: ${breakPoint.desktop}) {
		width: 85%;
	}
`
