export const breakPoint = {
	desktop: '75rem', // 1200px
	tablet: '59rem', // 944px
	ipad: '45rem', // 720px
	phone: '31rem', // 496px
}

export const heightHeader = 350

export const widthPages = '1200px'

export const fontFamily = 'Open Sans, sans-serif'
export const logoText = 'Grechen Fuemen'
export const subtitleText = 'Kalam'

export const fontWeight = {
	light: '300',
	regular: '400',
	medium: '500',
	middleBold: '600',
	bold: '700',
}

export const colors = {
	text: {
		white: '#ffffff',
		green: '#017723',
		grey: '#696767',
		lightGrey: '#868686',
		black: '#000000',
	},
	bg: {
		white: '#ffffff',
		green: '#017723',
		grey: '#eaeaea',
		lightGrey: '#868686',
		black: '#000000',
	},
}
