import React from 'react'
import NavBar from '../Navbar'
import Footer from '../../components/Footer'
import { Route, Switch } from 'react-router-dom'
import { Content, GlobalStyles } from './styled'

import WorkshopList from '../../pages/Workshop'
import HomePage from '../../pages/HomePage'
import Login from '../../pages/Login'
import Administration from '../../pages/Administration'
import EditCourse from '../../pages/EditCourse'
import Reservation from '../../pages/Reservation'
import Contact from '../../pages/Contact'
import DetailCourse from '../../pages/Detail'
import Terms from '../../pages/Terms'
import NewCourse from '../../pages/NewCourse'
import EmailSuccess from '../../pages/SuccessSend/email'
import ReservationSuccess from '../../pages/SuccessSend/reservation'
import Cookies from '../../pages/Cookies'

const Routes: React.FC = () => (
	<Switch>
		<Route path="/uspesna-rezervace" component={ReservationSuccess} />
		<Route path="/uspesne-odeslani" component={EmailSuccess} />
		<Route path="/podminky" component={Terms} />
		<Route path="/cookies" component={Cookies} />
		<Route path="/detail-kurzu/:id" component={DetailCourse} />
		<Route path="/kontakt" component={Contact} />
		<Route path="/rezervace/:id" component={Reservation} />
		<Route path="/nove-skoleni" component={NewCourse} />
		<Route path="/uprava-skoleni/:id" component={EditCourse} />
		<Route path="/administrace" component={Administration} />
		<Route path="/prihlaseni" component={Login} />
		<Route path="/nabidka-kurzu" component={WorkshopList} />
		<Route path="/" component={HomePage} />
	</Switch>
)

const App: React.FC = () => (
	<>
		<GlobalStyles />
		<NavBar />
		<Content>
			<Routes />
		</Content>
		<Footer />
	</>
)

export default App
