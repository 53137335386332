import React, { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import TitlePage from '../../components/TitlePage'
import * as texts from '../../texts/index.json'
import { container } from '../../services/firebase'
import { useUser } from '../../features/User'
import {
	ErrorMessage,
	LoginButton,
	LoginForm,
	LoginLabel,
	Wrapper,
} from './styled'
import { useHistory } from 'react-router-dom'
import { FormInput, FormItem } from '../../components/Form/styled'

type Inputs = {
	email: string
	password: string
}

const Login: React.FC = () => {
	const { register, handleSubmit } = useForm<Inputs>()
	const { updateUser } = useUser()
	const history = useHistory()
	const [loginError, setLoginError] = useState<boolean>(false)

	const onSubmit: SubmitHandler<Inputs> = async ({ email, password }) => {
		try {
			await container.authentication.signIn(email, password)
			updateUser(container.authentication.auth.currentUser)
			history.push('/administrace')
		} catch (error) {
			setLoginError(true)
			console.log(`Login error: ${error}`)
		}
	}

	return (
		<Wrapper>
			<TitlePage name={texts.pages.login.title} />
			<LoginForm onSubmit={handleSubmit(onSubmit)}>
				{/*------------------USERNAME------------------*/}
				<FormItem>
					<LoginLabel htmlFor="email">
						{texts.pages.login.formUsername.name}
					</LoginLabel>
					<FormInput
						id="email"
						name="email"
						type="email"
						placeholder={texts.pages.login.formUsername.placeholder}
						ref={register({
							required: true,
						})}
					/>
				</FormItem>
				{/*------------------PASSWORD------------------*/}
				<FormItem>
					<LoginLabel htmlFor="password">
						{texts.pages.login.formPassword.name}
					</LoginLabel>
					<FormInput
						id="password"
						name="password"
						type="password"
						placeholder={texts.pages.login.formPassword.placeholder}
						ref={register({
							required: true,
						})}
					/>
				</FormItem>
				<LoginButton type="submit">
					{texts.pages.login.formButton}
				</LoginButton>
				{loginError && (
					<ErrorMessage>{texts.pages.login.error}</ErrorMessage>
				)}
			</LoginForm>
		</Wrapper>
	)
}

export default Login
