import styled from 'styled-components'
import { colors } from '../../styled/variables'

export const BackArrow = styled.div`
	display: flex;
	width: 90%;
	margin-bottom: 25px;

	& > div {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: ${colors.text.grey};

		a {
			color: ${colors.text.grey};
		}

		&:hover div,
		&:hover a {
			color: ${colors.text.green};
		}
	}
`

export const Icon = styled.div`
	display: flex;
	width: 30px;
	margin: 0 10px 0 20px;
	color: ${colors.text.grey};
`
