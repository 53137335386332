import React from 'react'
import TitlePage from '../../components/TitlePage'
import * as texts from '../../texts/index.json'
import { ContactAddress, Info, Title, Wrapper, InfoPlace } from './styled'

const Contact: React.FC = () => (
	<Wrapper>
		<TitlePage name={texts.pages.contact.title} />
		<Info>
			<ContactAddress>
				<Title>{texts.pages.contact.subtitle1}</Title>
				<p>{texts.general.streetAddress}</p>
				<p>{texts.general.cityAddress}</p>
			</ContactAddress>
			<ContactAddress>
				<Title>{texts.pages.contact.subtitle2}</Title>
				<p>
					{texts.pages.contact.phone}
					<a href="tel:+420735729551">{texts.general.phoneNumber}</a>
				</p>
				<p>
					{texts.pages.contact.email}
					<a href="mailto:prihlaska@skolastik.cz">
						{texts.general.email}
					</a>
				</p>
			</ContactAddress>
		</Info>
		<InfoPlace>{texts.pages.contact.info}</InfoPlace>
	</Wrapper>
)

export default Contact
