import React from 'react'
import TitlePage from '../../components/TitlePage'
import * as texts from '../../texts/index.json'
import {
	Article,
	Wrapper,
	TitleArticle,
	ContentArticle,
	CircleList,
	NumberList,
	LastText,
} from '../Terms/styled'

const Cookies: React.FC = () => (
	<Wrapper>
		<TitlePage name={texts.pages.gdpr.title} />
		<Article>
			<TitleArticle>I. Základní ustanovení</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Správcem osobních údajů podle čl. 4 bod 7 nařízení
						Evropského parlamentu a Rady (EU) 2016/679 o ochraně
						fyzických osob v souvislosti se zpracováním osobních
						údajů a o volném pohybu těchto údajů (dále jen:{' '}
						<b>„GDPR”</b>) je <b>ŠKOLASTIK s.r.o.</b> IČO{' '}
						<b>27399656</b> se sídlem{' '}
						<b>Mladá Boleslav II, Oválná 1410</b> (dále jen:{' '}
						<b>„správce“</b>).
					</li>
					<li>
						Osobními údaji se rozumí veškeré informace o
						identifikované nebo identifikovatelné fyzické osobě;
						identifikovatelnou fyzickou osobou je fyzická osoba,
						kterou lze přímo či nepřímo identifikovat, zejména
						odkazem na určitý identifikátor, například jméno,
						identifikační číslo, lokační údaje, síťový identifikátor
						nebo na jeden či více zvláštních prvků fyzické,
						fyziologické, genetické, psychické, ekonomické, kulturní
						nebo společenské identity této fyzické osoby.
					</li>
					<li>
						Správce nejmenoval pověřence pro ochranu osobních údajů.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>
				II. Zdroje a kategorie zpracovávaných osobních údajů
			</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Správce zpracovává osobní údaje, které jste mu poskytl/a
						nebo osobní údaje, které správce získal na základě
						plnění právní povinnosti, plnění smlouvy, oprávněný a
						životně důležitý zájem, souhlas subjektu údajů se
						zpracováním osobních údajů.
					</li>
					<li>
						Správce zpracovává Vaše identifikační a kontaktní údaje
						a údaje nezbytné pro personalizaci obsahu, reklam,
						analýzy návštěvnosti a plnění smlouvy.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>
				III. Zákonný důvod a účel zpracování osobních údajů
			</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Zpracování osobních údajů na základě oprávněného zájmu
						Správce
						<CircleList>
							<li>
								v některých případech zpracovává Správce osobní
								údaje, aby zajistil ochranu svých práv a právem
								chráněných zájmů, případně práv a právem
								chráněných zájmů dalších subjektů. Takové
								zpracování může Správce provádět bez souhlasu
								subjektu údajů. Existenci oprávněného zájmu
								Správce vždy pečlivě posuzuje.
							</li>
						</CircleList>
					</li>
					<li>
						Zpracování osobních údajů na základě smluvních podmínek
						<CircleList>
							<li>
								plnění smlouvy mezi Vámi a správcem podle čl. 6
								odst. 1 písm. b) GDPR,
							</li>
							<li>
								oprávněný zájem správce na poskytování přímého
								marketingu (zejména pro zasílání obchodních
								sdělení a newsletterů) podle čl. 6 odst. 1 písm.
								f) GDPR,
							</li>
							<li>
								Váš souhlas se zpracováním pro účely poskytování
								přímého marketingu (zejména pro zasílání
								obchodních sdělení a newsletterů) podle čl. 6
								odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2
								zákona č. 480/2004 Sb., o některých službách
								informační společnosti v případě, že nedošlo k
								objednávce zboží nebo služby.
							</li>
							<li>
								vyřízení Vaší objednávky a výkon práv a
								povinností vyplývajících ze smluvního vztahu
								mezi Vámi a správcem; při objednávce jsou
								vyžadovány osobní údaje, které jsou nutné pro
								úspěšné vyřízení objednávky (jméno a adresa,
								kontakt), poskytnutí osobních údajů je nutným
								požadavkem pro uzavření a plnění smlouvy, bez
								poskytnutí osobních údajů není možné smlouvu
								uzavřít či jí ze strany správce plnit,
							</li>
						</CircleList>
					</li>
					<li>
						Účelem zpracování osobních údajů je
						<CircleList>
							<li>
								vyřízení Vaší objednávky a výkon práv a
								povinností vyplývajících ze smluvního vztahu
								mezi Vámi a správcem; při objednávce jsou
								vyžadovány osobní údaje, které jsou nutné pro
								úspěšné vyřízení objednávky (jméno a adresa,
								kontakt), poskytnutí osobních údajů je nutným
								požadavkem pro uzavření a plnění smlouvy, bez
								poskytnutí osobních údajů není možné smlouvu
								uzavřít či jí ze strany správce plnit,
							</li>
						</CircleList>
					</li>
					<li>
						Zpracování osobních údajů na základě uděleného souhlasu
						subjektů údajů
						<CircleList>
							<li>
								Správce zpracovává osobní údaje na základě
								uděleného souhlasu v případech, které naleznete
								vypsané u správce.
							</li>
						</CircleList>
					</li>
					<li>
						Ze strany správce nedochází k automatickému
						individuálnímu rozhodování ve smyslu čl. 22 GDPR.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>IV. Doba uchovávání údajů</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Správce uchovává osobní údaje
						<CircleList>
							<li>po dobu trvání vzájemného smluvního vztahu</li>
							<li>
								po dobu nezbytnou k výkonu práv a povinností
								vyplývajících ze smluvního vztahu mezi Vámi a
								správcem a uplatňování nároků z těchto smluvních
								vztahů (po dobu 5 let od ukončení smluvního
								vztahu).
							</li>
							<li>
								po dobu, než je odvolán souhlas se zpracováním
								osobních údajů pro účely marketingu, nejdéle 5
								let, jsou-li osobní údaje zpracovávány na
								základě souhlasu.
							</li>
						</CircleList>
					</li>
					<li>
						Po uplynutí doby uchovávání osobních údajů správce
						osobní údaje vymaže.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>
				V. Příjemci osobních údajů (subdodavatelé správce)
			</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Příjemci osobních údajů jsou osoby
						<CircleList>
							<li>
								podílející se na dodání zboží / služeb /
								realizaci plateb na základě smlouvy
							</li>
						</CircleList>
					</li>
					<li>
						Správce nemá v úmyslu předat osobní údaje do třetí země
						nebo mezinárodní organizace a existenci či neexistenci
						rozhodnutí Komise o odpovídající ochraně nebo, v
						případech předání uvedených v článcích 46, 47 nebo čl.
						49 odst. 1 druhém pododstavci, odkaz na vhodné záruky a
						prostředky k získání kopie těchto údajů nebo informace o
						tom, kde byly tyto údaje zpřístupněny.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>VI. COOKIES</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Na našich internetových stránkách používáme soubory
						„cookies“ proto, abychom návštěvníkům poskytli větší
						pohodlí při jejich prohlížení. Soubory „cookies“ (v
						doslovném překladu „sušenky“) jsou malé textové soubory,
						které Internetové Stránky vytvoří ve Vašem zařízení v
						momentě, kdy na ně vstoupíte.
					</li>
					<li>
						Naše internetové stránky používají jak tzv. „session
						cookies“, tak i tzv. „persistent cookies“.
						<CircleList>
							<li>
								Session cookies umožňují listovat jednotlivými
								záložkami našich stránek a pamatují si
								jakoukoliv informaci, kterou zde zadáte. Session
								cookie se automaticky smaže v okamžiku, kdy
								prohlížeč zavřete, nebo krátce poté.
							</li>
							<li>
								Persistent cookies umožňují těmto internetovým
								stránkám pamatovat si Vaše preference a
								nastavení pro Vaši příští návštěvu a díky nim se
								např. nemusíte neustále přihlašovat. Persistent
								cookies po určitém nastaveném čase automaticky
								vyprší.
							</li>
						</CircleList>
					</li>
					<li>
						Naše internetové stránky používají cookies, které:
						<CircleList>
							<li>
								zlepšují výkon Internetových Stránek tím, že
								sbírají informace o způsobu, jakým je
								návštěvníci používají (např. které záložky jsou
								nejvíce navštěvované).
							</li>
							<li>
								zvyšují funkčnost Internetových Stránek a
								přibližují je Vám tím, že nám umožňují si
								pamatovat Vaše dřívější volby. Tyto informace
								nicméně nejsou používány k Vaší identifikaci ani
								k zapamatování si toho, kde jste na internetu
								byli.
							</li>
							<li>
								umožňují některým nástrojům pracovat, poskytují
								opatření proti spamům a také přístup k věkově
								omezenému obsahu.
							</li>
							<li>
								umožňují některým našim dodavatelům poskytovat
								služby, které ve vztahu k Internetovým Stránkám
								poskytují v našem zastoupení.
							</li>
						</CircleList>
					</li>
					<li>
						„Cookies“ nejsou viry. Jsou to pouze textové soubory,
						které neobsahují žádný program a vzhledem k tomu se ani
						nedají spustit jako program. Soubor „cookies“ se nemůže
						sám kopírovat a rozesílat po internetové síti. Váš
						internetový prohlížeč je však při každé návštěvě odešle
						Internetové Stránce, ke které patří, a ta se pak v
						internetovém prohlížeči zobrazí ve Vašem nastavení.
					</li>
					<li>
						Pomocí souborů „cookies“ dokážeme v obecné rovině také
						zaznamenávat statistické údaje o chování návštěvníka
						těchto Internetových Stránek. Díky tomu je můžeme cíleně
						přizpůsobovat Vašim zájmům a požadavkům.
					</li>
					<li>
						„Cookies“ nám pomáhají při identifikaci zvlášť populární
						či problémové části Internetových Stránek, není je ale
						možné spojit s konkrétním uživatelem.
					</li>
					<li>
						Soubory „cookies“ dále využíváme i pro účely Vaší
						autentizace, tedy pro to, abychom jejich prostřednictvím
						eliminovali nutnost zadávání identifikačních údajů o
						Vaší osobě při každém dalším Vašem vstupu na Internetové
						Stránky. Současně je používáme i pro účely uzpůsobení
						Internetových Stránek dle Vašich potřeb, tedy např. pro
						to, aby se Vám Internetové Stránky nadále zobrazovaly ve
						Vámi zvoleném jazyce či v určité grafické úpravě.
					</li>
					<li>
						Tyto Internetové Stránky si samozřejmě můžete prohlížet
						i bez souborů „cookies“, je však možné, že některé
						jejich funkčnosti budou omezené a komfort používání se
						sníží. Většina prohlížečů tyto soubory akceptuje
						automaticky, jejich ukládání je však možno zabránit tak,
						že v nastaveních prohlížeče zvolíte možnost
						„neakceptovat cookies“. Soubory „cookies“, které se na
						Vašem zařízení již uložily, můžete také kdykoliv
						vymazat. Přesné nastavení této funkce zjistíte pomocí
						„nápovědy “ Vašeho prohlížeče.
					</li>
					<li>
						Používáním Internetových Stránek vyjadřujete souhlas s
						použitím souborů „cookies“ výše uvedeným způsobem.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>VII. Vaše práva</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Za podmínek stanovených v GDPR máte
						<CircleList>
							<li>
								právo na přístup ke svým osobním údajům dle čl.
								15 GDPR,
							</li>
							<li>
								právo na opravu osobních údajů dle čl. 16 GDPR,
								popřípadě omezení zpracování dle čl. 18 GDPR.
							</li>
							<li>
								právo na výmaz osobních údajů dle čl. 17 GDPR.
							</li>
							<li>
								právo vznést námitku proti zpracování dle čl. 21
								GDPR a
							</li>
							<li>
								právo na přenositelnost údajů dle čl. 20 GDPR.
							</li>
							<li>
								právo odvolat souhlas se zpracováním písemně
								nebo elektronicky na adresu nebo email správce
								uvedený v čl. III těchto podmínek, aniž je tím
								dotčena zákonnost zpracování založená na
								souhlasu uděleném před jeho odvoláním.
							</li>
						</CircleList>
					</li>
					<li>
						Dále máte právo podat stížnost u Úřadu pro ochranu
						osobních údajů v případě, že se domníváte, že bylo
						porušeno Vaše právo na ochranu osobních údajů.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>
				VIII. Podmínky zabezpečení osobních údajů
			</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Správce prohlašuje, že přijal veškerá vhodná technická a
						organizační opatření k zabezpečení osobních údajů.
					</li>
					<li>
						Správce přijal technická opatření k zabezpečení datových
						úložišť a úložišť osobních údajů v listinné podobě,
						zejména pomocí hesla, antivirového programu a záloh.
					</li>
					<li>
						Správce prohlašuje, že k osobním údajům mají přístup
						pouze jím pověřené osoby.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>IX. Závěrečná ustanovení</TitleArticle>
			<ContentArticle>
				<NumberList>
					<li>
						Odesláním objednávky z internetového objednávkového
						formuláře potvrzujete, že jste seznámen/a s podmínkami
						ochrany osobních údajů a že je v celém rozsahu
						přijímáte.
					</li>
					<li>
						S těmito podmínkami souhlasíte zaškrtnutím souhlasu
						prostřednictvím internetového formuláře. Zaškrtnutím
						souhlasu potvrzujete, že jste seznámen/a s podmínkami
						ochrany osobních údajů a že je v celém rozsahu
						přijímáte.
					</li>
					<li>
						Správce je oprávněn tyto podmínky změnit. Novou verzi
						podmínek ochrany osobních údajů zveřejní na svých
						internetových stránkách a zároveň Vám zašle novou verzi
						těchto podmínek Vaši e-mailovou adresu, kterou jste
						správci poskytl/a.
					</li>
				</NumberList>
			</ContentArticle>
		</Article>
		<Article>
			<LastText>
				Tyto podmínky nabývají účinnosti dnem 1. 11. 2021.
			</LastText>
			<LastText>&#169; Veškerá práva jsou vyhrazena.</LastText>
			<LastText>
				Kopírování veškerého obsahu v jakékoliv podobě bez předchozího
				písemného nebo ústního souhlasu je zakázáno a je trestné.
			</LastText>
		</Article>
	</Wrapper>
)

export default Cookies
