import React from 'react'
import { Wrapper, Title } from './styled'

type Props = {
	name: string
}
const TitlePage: React.FC<Props> = ({ name }) => (
	<Wrapper>
		<Title>{name}</Title>
	</Wrapper>
)

export default TitlePage
