import React, { useEffect, useState } from 'react'
import ItemWorkshop from '../../components/ItemWorkshop'
import TitlePage from '../../components/TitlePage'
import FormOnTheWorkshop from '../../features/FormOnTheWorkshop'
import { container, Event } from '../../services/firebase'
import { Course, NoCourse, Wrapper } from './styled'
import * as texts from '../../texts/index.json'

const WorkshopList: React.FC = () => {
	const [events, setEvents] = useState<Event[]>([])

	useEffect(() => {
		container.collections.events
			.getAllEvents()
			.then((events) => setEvents(events))
	}, [])

	const actualTime = new Date().getTime()
	const lastEvents = events
		.filter((event) => event.from < actualTime)
		.sort((a, b) => (a.from > b.from ? -1 : 1))
	const prepareEvents = events
		.filter((event) => event.from > actualTime)
		.sort((a, b) => (a.from < b.from ? -1 : 1))

	return (
		<Wrapper>
			<TitlePage name={texts.pages.workshop.title} />
			<Course>{texts.pages.workshop.upcomingCourses}</Course>
			{prepareEvents.length === 0 ? (
				<NoCourse>{texts.pages.workshop.noCourse}</NoCourse>
			) : (
				prepareEvents.map((event, index) => (
					<ItemWorkshop
						key={index}
						name={event.name}
						speaker={event.speakerName}
						to={event.to}
						from={event.from}
						id={event.id}
						enableReservation={true}
					/>
				))
			)}
			<Course>{texts.pages.workshop.pastCourses}</Course>
			{lastEvents.map((event, index) => (
				<ItemWorkshop
					key={index}
					name={event.name}
					speaker={event.speakerName}
					to={event.to}
					from={event.from}
					id={event.id}
					enableReservation={false}
				/>
			))}
			<FormOnTheWorkshop />
		</Wrapper>
	)
}

export default WorkshopList
