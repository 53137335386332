import styled from 'styled-components'
import {
	breakPoint,
	colors,
	fontWeight,
	widthPages,
} from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	height: 55vh;
	flex-direction: column;
	align-items: center;
	margin: 0;

	@media (max-width: ${breakPoint.desktop}) {
		width: 90%;
	}
`

export const Info = styled.section`
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;

	@media (max-width: ${breakPoint.ipad}) {
		flex-direction: column;
		gap: 30px;
	}
`

export const ContactAddress = styled.div`
	p {
		color: ${colors.text.grey};
		margin: 7px 0;
	}

	a {
		color: ${colors.text.green};
		font-weight: ${fontWeight.medium};

		&:hover {
			filter: brightness(120%);
		}
	}

	@media (max-width: ${breakPoint.ipad}) {
		text-align: center;
	}
`

export const Title = styled.h6`
	font-size: 1.3rem;
	font-weight: ${fontWeight.middleBold};
	color: ${colors.text.green};
	margin: 0 0 15px 0;
`

export const InfoPlace = styled.p`
	color: ${colors.text.green};
	padding-top: 30px;
`
