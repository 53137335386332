import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
	colors,
	breakPoint,
	widthPages,
	fontWeight,
} from '../../../styled/variables'

export const Wrapper = styled.nav<{ $sticky: boolean }>`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${colors.bg.green};
	background-color: ${colors.bg.white};
	position: ${({ $sticky }) => ($sticky ? 'fixed' : 'relative')};
	top: 0;
	animation: ${({ $sticky }) => ($sticky ? 'green' : 'white')} 1s forwards;
	z-index: 999;

	@keyframes green {
		0% {
			background-color: ${colors.bg.white};
		}
		100% {
			background-color: ${colors.bg.green};
		}
	}

	@keyframes white {
		0% {
			background-color: ${colors.bg.green};
		}
		100% {
			background-color: ${colors.bg.white};
		}
	}

	@media (max-width: ${breakPoint.phone}) {
		height: 100px;
		border: none;
		position: relative;
		animation: none;
	}
`

export const List = styled.ul`
	width: ${widthPages};
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}

	@media (max-width: ${breakPoint.tablet}) {
		justify-content: space-around;
	}

	@media (max-width: ${breakPoint.phone}) {
		flex-direction: column;
		justify-content: center;
	}
`

export const ItemOfList = styled.li`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 70px;

	@media (max-width: ${breakPoint.tablet}) {
		padding: 0;
	}

	@media (max-width: ${breakPoint.phone}) {
		width: 100%;
		text-align: center;
		padding: 6px 0 4px 0;
		border-bottom: 2px solid ${colors.bg.green};
	}
`

export const StyledLink = styled(Link)<{ $sticky: boolean }>`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ $sticky }) =>
		$sticky ? `${colors.text.white}` : `${colors.text.green}`};
	font-weight: ${fontWeight.medium};

	&:hover {
		text-shadow: ${({ $sticky }) =>
			$sticky
				? `0.5px 0.5px 8px ${colors.text.white}`
				: `0.5px 0.5px 10px ${colors.text.green}`};
	}
`
