import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { breakPoint, colors } from '../../styled/variables'

export const Wrapper = styled.footer`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: ${colors.bg.green};
	padding: 10px;
	letter-spacing: 0.5px;

	span:last-child {
		border-right: none;
	}

	@media (max-width: ${breakPoint.ipad}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
	}
`
const text = css`
	color: ${colors.text.white};
	padding: 0 20px;
	font-size: 0.9rem;
	border-right: 1px solid ${colors.text.white};

	@media (max-width: ${breakPoint.ipad}) {
		border-bottom: 1px solid ${colors.text.white};
		border-right: none;
		width: 100%;
		padding: 7px;
		text-align: center;
	}
`

export const StyledText = styled.span`
	${text};
`

export const Login = styled(Link)`
	${text};

	&:hover {
		text-shadow: 0.5px 0.5px 6px ${colors.text.white};
	}
`

export const Logout = styled.span`
	${text};
	cursor: pointer;

	&:hover {
		text-shadow: 0.5px 0.5px 6px ${colors.text.white};
	}
`
