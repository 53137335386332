import styled from 'styled-components'
import { Button, Form, FormLabel } from '../../components/Form/styled'
import { breakPoint, colors } from '../../styled/variables'

export const Wrapper = styled.section`
	width: 100%;
	height: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: ${breakPoint.phone}) {
		height: 700px;
	}
`

export const LoginForm = styled(Form)`
	width: 450px;
	padding: 30px;
	background-color: ${colors.bg.green};
	border-radius: 15px;

	@media (max-width: ${breakPoint.phone}) {
		width: 95%;
	}
`

export const LoginLabel = styled(FormLabel)`
	color: ${colors.text.white};
`

export const LoginButton = styled(Button)`
	background-color: ${colors.bg.white};
	color: ${colors.text.green};
	letter-spacing: 0.7px;
`

export const ErrorMessage = styled.p`
	color: #e40404;
	text-align: center;
	background-color: ${colors.bg.white};
	border: 1px solid #e40404;
	border-radius: 10px;
	padding: 10px;
`
