import styled from 'styled-components'
import { colors, fontWeight } from '../../styled/variables'

export const Wrapper = styled.section`
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;

	button {
		margin-top: 15px;
	}
`

export const Title = styled.h4`
	text-align: center;
	font-size: 1.5rem;
	font-weight: ${fontWeight.middleBold};
	color: ${colors.text.green};
	margin: 60px 0 20px 0;
`

export const SubTitle = styled.p`
	text-align: center;
	font-size: 1rem;
	color: ${colors.text.grey};
	margin-bottom: 30px;
`
