import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { basicButton } from '../../components/Form/styled'
import {
	breakPoint,
	colors,
	fontFamily,
	fontWeight,
	widthPages,
} from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	margin: 70px 0;
	align-items: center;

	@media (max-width: ${breakPoint.desktop}) {
		width: 85%;
	}

	@media (max-width: ${breakPoint.ipad}) {
		width: 90%;
	}
`

export const TitleCourse = styled.span`
	width: 90%;
	font-size: 1.4rem;
	color: ${colors.text.black};
	font-weight: ${fontWeight.bold};
	margin-bottom: 30px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}
`

export const DateInfo = styled.div`
	display: grid;
	width: 85%;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: center;
	align-items: center;
	padding: 15px;
	border-bottom: 1px solid ${colors.bg.white};

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}

	@media (max-width: ${breakPoint.ipad}) {
		grid-template-columns: 1fr 1fr;

		button {
			grid-column: 1/3;
			margin-top: 25px;
		}
	}

	@media (max-width: ${breakPoint.phone}) {
		grid-template-columns: 1fr;
		row-gap: 20px;

		button {
			grid-column: 1;
			margin-top: 0;
		}
	}
`
export const DivInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	filter: brightness(80%);
`

export const Icon = styled.div`
	width: 25px;
	margin-right: 7px;
`

export const Title = styled.p`
	width: 90%;
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	padding: 0 25px;
	font-size: 1.2rem;
	margin: 25px 0 10px 0;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}
`

export const ContentCourse = styled.pre`
	background-color: ${colors.bg.white};
	width: 90%;
	padding: 27px;
	border-radius: 15px;
	color: ${colors.text.grey};
	font-family: ${fontFamily};
	white-space: pre-wrap;
	overflow-wrap: break-word;

	p {
		margin: 5px 0;
	}

	@media (max-width: ${breakPoint.desktop}) {
		width: 700px;
	}
`

export const Teacher = styled.div`
	background-color: ${colors.bg.white};
	width: 90%;
	display: flex;
	padding: 20px;
	border-radius: 15px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}

	@media (max-width: ${breakPoint.ipad}) {
		flex-direction: column;
		align-items: center;
	}
`

export const Picture = styled.div`
	padding: 2.5px 0 0 10px;
`

export const Avatar = styled.img`
	background-color: ${colors.bg.white};
	border-radius: 50%;
	border: 2px solid ${colors.bg.grey};
`

export const Description = styled.div`
	margin-left: 30px;
`

export const Name = styled.p`
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	font-size: 1.2rem;
`

export const TeacherText = styled.pre`
	color: ${colors.text.grey};
	font-family: ${fontFamily};
	white-space: pre-wrap;
	overflow-wrap: break-word;
`

export const Price = styled.p`
	padding: 20px;
	color: ${colors.text.green};
`

export const Place = styled.p`
	color: ${colors.text.green};
	padding: 10px 0 0 0;
`

export const LinkButton = styled(Link)`
	${basicButton};
	text-align: center;
`
