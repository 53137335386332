import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
	breakPoint,
	colors,
	fontWeight,
	widthPages,
} from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	margin: 70px 0;

	@media (max-width: ${breakPoint.desktop}) {
		width: 90%;
	}
`

export const Container2Columns = styled.section`
	display: flex;
	align-items: center;

	@media (max-width: ${breakPoint.ipad}) {
		flex-direction: column;
	}
`

export const Container1Columns = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Picture = styled.img`
	width: 50%;
	height: 500px;
	object-fit: cover;

	@media (max-width: ${breakPoint.desktop}) {
		width: 40%;
		height: auto;
	}

	@media (max-width: ${breakPoint.tablet}) {
		width: 300px;
	}

	@media (max-width: ${breakPoint.ipad}) {
		width: 90%;
		height: 420px;
		object-fit: cover;
		order: 2;
	}

	@media (max-width: ${breakPoint.phone}) {
		height: 270px;
	}
`

export const Article = styled.div`
	background-color: ${colors.bg.white};
	height: 90%;
	width: 45%;

	@media (max-width: ${breakPoint.desktop}) {
		width: 55%;
	}

	@media (max-width: ${breakPoint.tablet}) {
		width: 60%;
		height: 95%;
	}

	@media (max-width: ${breakPoint.ipad}) {
		width: 90%;
	}
`

export const Headline = styled.p`
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	font-size: 1.3rem;
	padding: 40px 40px 0 50px;
	margin: 0;

	@media (max-width: ${breakPoint.tablet}) {
		padding: 20px 30px 0 30px;
	}

	@media (max-width: ${breakPoint.ipad}) {
		font-size: 1.1rem;
	}
`

const samePaper = css`
	background-color: ${colors.bg.white};
	width: 95%;
	color: ${colors.text.grey};
	font-size: 1.2rem;
	line-height: 1.7rem;
	padding: 25px 50px;

	@media (max-width: ${breakPoint.desktop}) {
		line-height: 1.5rem;
	}

	@media (max-width: ${breakPoint.tablet}) {
		padding: 16px 30px;
		font-size: 1.1rem;
		line-height: 1.4rem;
	}

	@media (max-width: ${breakPoint.ipad}) {
		font-size: 1rem;
	}
`

export const Paper = styled.article`
	${samePaper};

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}
`

export const Paper2 = styled.article`
	${samePaper};
`

export const Button = styled(Link)`
	background-color: ${colors.bg.green};
	margin: 20px 0;
	padding: 10px 40px;
	border-radius: 8px;
	color: ${colors.text.white};
	letter-spacing: 0.5px;

	&:hover {
		background-color: ${colors.bg.green};
		filter: brightness(120%);
	}

	@media (max-width: ${breakPoint.phone}) {
		padding: 10px 20px;
	}
`

export const Title = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 30px 0;
`

export const Name = styled.p`
	width: 30%;
	text-align: center;
	font-size: 1.7rem;
	font-weight: ${fontWeight.middleBold};
	color: ${colors.text.green};

	@media (max-width: ${breakPoint.ipad}) {
		width: 40%;
	}

	@media (max-width: ${breakPoint.phone}) {
		width: 50%;
		font-size: 1.5rem;
	}
`

export const Line = styled.div`
	background-color: ${colors.bg.green};
	height: 1px;
	width: 70%;

	@media (max-width: ${breakPoint.ipad}) {
		width: 60%;
	}

	@media (max-width: ${breakPoint.phone}) {
		width: 50%;
	}
`

export const Info = styled.div`
	background-color: ${colors.bg.white};
	width: 40%;
	height: 80%;
	margin-left: 10%;
	padding-left: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: ${breakPoint.tablet}) {
		width: 45%;
		margin-left: 5%;
		padding-left: 50px;
	}

	@media (max-width: ${breakPoint.ipad}) {
		width: 70%;
		margin: 0;
		padding: 0;
		align-items: center;
		text-align: center;
		height: 50%;
	}

	@media (max-width: ${breakPoint.phone}) {
		width: 80%;
	}
`

export const Company = styled.span`
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	font-size: 1.2rem;

	@media (max-width: ${breakPoint.phone}) {
		font-size: 1.1rem;
	}
`

export const Address = styled.span`
	color: ${colors.text.grey};
	margin: 15px 0;
	line-height: 1.6em;

	a {
		color: ${colors.text.green};
		font-weight: ${fontWeight.medium};

		&:hover {
			filter: brightness(120%);
		}
	}

	@media (max-width: ${breakPoint.phone}) {
		font-size: 0.9rem;
	}
`

export const Mapa = styled.div`
	background-color: darkgray;
	width: 50%;
	height: 500px;
	border: 2px solid ${colors.bg.white};
	position: relative;

	@media (max-width: ${breakPoint.tablet}) {
		height: 400px;
	}

	@media (max-width: ${breakPoint.ipad}) {
		width: 90%;
		height: 500px;
	}

	@media (max-width: ${breakPoint.phone}) {
		height: 350px;
	}
`

export const Meeting = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
`
