import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components'
import { colors } from '../../styled/variables'

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${colors.bg.grey};
    }

    a, button {
        cursor: pointer;
        text-decoration: none;
        border: none;
        outline: none;
        background-color: transparent;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`

export const Content = styled.main`
	position: relative;
	z-index: 998;
	background-color: ${colors.bg.grey};
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`
