import { User } from 'firebase/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { container } from '../../services/firebase'

type UserContextType = {
	user: User | null
	isLoggedIn: boolean
	updateUser: (user: User | null) => void
}

const UserContext = createContext<UserContextType>({
	user: null,
	isLoggedIn: false,
	updateUser: () => {
		return
	},
})

const UserProvider: React.FC = ({ children }) => {
	const [user, setUser] = useState<User | null>(null)

	useEffect(
		() =>
			container.authentication.auth.onAuthStateChanged((user) =>
				user ? setUser(user) : setUser(null)
			),
		[setUser]
	)

	const isLoggedIn = Boolean(user)

	const updateUser = (user: User | null) => {
		setUser(user)
	}

	return (
		<UserContext.Provider
			value={{
				user,
				isLoggedIn,
				updateUser,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}

export const useUser = (): UserContextType => useContext(UserContext)

export default UserProvider
