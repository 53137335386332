import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import ReCAPTCHA from 'react-google-recaptcha'

import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import {
	Button,
	ErrorMessage,
	Form,
	FormInput,
	FormItem,
	FormLabel,
	FormTextArea,
} from '../../components/Form/styled'
import * as texts from '../../texts/index.json'
import { SubTitle, Title, Wrapper } from './styled'

type FormData = {
	name: string
	email: string
	message: string
}

const FormOnTheWorkshop: React.FC = () => {
	const { register, handleSubmit, errors, reset } = useForm()

	const history = useHistory()

	const [isRobot, setIsRobot] = useState(true)
	const [captchaError, setCaptchaError] = useState(false)

	const onChange = (token: string | null) =>
		token ? setIsRobot(false) : setIsRobot(true)

	const onSubmit = async (data: FormData) => {
		if (isRobot) {
			setCaptchaError(true)

			return
		}
		setCaptchaError(false)
		reset()

		try {
			emailjs.send(
				process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
				{
					message: data.message,
					name: data.name,
					email_for_response: data.email,
				},
				process.env.REACT_APP_EMAILJS_USER_ID || ''
			)

			history.push('/uspesne-odeslani')
		} catch (error) {
			console.log(error)
		}
	}

	const nameError = `${texts.pages.workshop.formName.error}`
	const emailError = `${texts.pages.workshop.formEmail.error}`
	const messageError = `${texts.pages.workshop.formMessage.error}`

	return (
		<Wrapper>
			<Title>{texts.pages.workshop.formTitle}</Title>
			<SubTitle>{texts.pages.workshop.formSubtitle}</SubTitle>
			<Form onSubmit={handleSubmit(onSubmit)}>
				{/*------------------NAME------------------*/}
				<FormItem>
					<FormLabel htmlFor="name">
						{texts.pages.workshop.formName.name}
					</FormLabel>
					<FormInput
						id="name"
						name="name"
						type="text"
						placeholder={texts.pages.workshop.formName.placeholder}
						ref={register({
							required: nameError,
							pattern: {
								value: /^[a-zA-Zá-žÁ-Ž\s]+$/i,
								message: `${texts.pages.workshop.formName.errorPattern}`,
							},
							maxLength: {
								value: 50,
								message: `${texts.pages.workshop.formName.errorMaxLength}`,
							},
							minLength: {
								value: 3,
								message: `${texts.pages.workshop.formName.errorMinLength}`,
							},
						})}
					/>
					{errors.name && (
						<ErrorMessage>{errors.name.message}</ErrorMessage>
					)}
				</FormItem>
				{/*------------------EMAIL------------------*/}
				<FormItem>
					<FormLabel htmlFor="email">
						{texts.pages.workshop.formEmail.name}
					</FormLabel>
					<FormInput
						id="email"
						name="email"
						type="email"
						placeholder={texts.pages.workshop.formEmail.placeholder}
						ref={register({
							required: emailError,
							pattern: {
								value: /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: `${texts.pages.workshop.formEmail.errorPattern}`,
							},
							maxLength: {
								value: 254,
								message: `${texts.pages.workshop.formEmail.errorMaxLength}`,
							},
						})}
					/>
					{errors.email && (
						<ErrorMessage>{errors.email.message}</ErrorMessage>
					)}
				</FormItem>
				{/*------------------MESSAGE------------------*/}
				<FormItem>
					<FormLabel htmlFor="message">
						{texts.pages.workshop.formMessage.name}
					</FormLabel>
					<FormTextArea
						id="message"
						name="message"
						placeholder={
							texts.pages.workshop.formMessage.placeholder
						}
						ref={register({
							required: messageError,
						})}
					/>
					{errors.message && (
						<ErrorMessage>{errors.message.message}</ErrorMessage>
					)}
				</FormItem>
				{/* TODO: Verify that it works well without token callback */}
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
					onChange={onChange}
					theme="light"
				/>
				{captchaError && (
					<ErrorMessage>
						{texts.pages.reservation.captcha.error}
					</ErrorMessage>
				)}
				<Button type="submit">{texts.pages.workshop.formButton}</Button>
			</Form>
		</Wrapper>
	)
}

export default FormOnTheWorkshop
