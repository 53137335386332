import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from 'emailjs-com'

import * as texts from '../../texts/index.json'
import {
	Button,
	ErrorMessage,
	Form,
	FormCheckbox,
	FormInput,
	FormItem,
	FormLabel,
	FormTextArea,
} from '../../components/Form/styled'
import TitlePage from '../../components/TitlePage'
import { StyledLink, Wrapper } from './styled'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { container, Event } from '../../services/firebase'

type ReservationData = {
	name: string
	email: string
	phone?: string
	nameCompany?: string
	street?: string
	city?: string
	postNumber?: string
	identifier?: string
	dIdentifier?: string
	message?: string
	news?: boolean
	terms: boolean
}

const Reservation: React.FC = () => {
	const { register, handleSubmit, errors, reset } = useForm()
	const [event, setEvent] = useState<Event | null>()

	const { id } = useParams<{ id: string }>()

	useEffect(() => {
		container.collections.events.getEvent(id).then((info) => setEvent(info))
	}, [id])

	const history = useHistory()

	const [isRobot, setIsRobot] = useState(true)
	const [captchaError, setCaptchaError] = useState(false)

	const onChange = (token: string | null) =>
		token ? setIsRobot(false) : setIsRobot(true)

	const onSubmit = async (data: ReservationData) => {
		if (isRobot) {
			setCaptchaError(true)

			return
		}
		setCaptchaError(false)

		if (!event) return

		const dateFrom = new Date(event.from)

		const dateFromFormate = `${dateFrom.getUTCDate()}. ${
			dateFrom.getUTCMonth() + 1
		}. ${dateFrom.getFullYear()}`

		const placeHolder = '-'

		console.log('Reservation was sent', {
			event_name: event.name,
			event_from: dateFromFormate,
			event_price: event.price,
			reservation_name: data.name,
			reservation_email: data.email,
			reservation_phone: data.phone === '' ? placeHolder : data?.phone,
			reservation_identifier:
				data.identifier === '' ? placeHolder : data?.identifier,
			reservation_d_identifier:
				data?.dIdentifier === '' ? placeHolder : data?.dIdentifier,
			reservation_name_company:
				data.nameCompany === '' ? placeHolder : data?.nameCompany,
			reservation_street: data.street === '' ? placeHolder : data?.street,
			reservation_city: data.city === '' ? placeHolder : data?.city,
			reservation_psc:
				data.postNumber === '' ? placeHolder : data?.postNumber,
			reservation_terms: data?.terms === true ? 'Ano' : 'Ne',
			reservation_news: data?.news === true ? 'Ano' : 'Ne',
			reservation_message:
				data.message === '' ? placeHolder : data?.message,
		})

		emailjs.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
			process.env.REACT_APP_EMAILJS_RESERVATION_TEMPLATE_ID || '',
			{
				event_name: event.name,
				event_from: dateFromFormate,
				event_price: event.price,
				reservation_name: data.name,
				reservation_email: data.email,
				reservation_phone:
					data.phone === '' ? placeHolder : data?.phone,
				reservation_identifier:
					data.identifier === '' ? placeHolder : data?.identifier,
				reservation_d_identifier:
					data?.dIdentifier === '' ? placeHolder : data?.dIdentifier,
				reservation_name_company:
					data.nameCompany === '' ? placeHolder : data?.nameCompany,
				reservation_street:
					data.street === '' ? placeHolder : data?.street,
				reservation_city: data.city === '' ? placeHolder : data?.city,
				reservation_psc:
					data.postNumber === '' ? placeHolder : data?.postNumber,
				reservation_terms: data?.terms === true ? 'Ano' : 'Ne',
				reservation_news: data?.news === true ? 'Ano' : 'Ne',
				reservation_message:
					data.message === '' ? placeHolder : data?.message,
			},
			process.env.REACT_APP_EMAILJS_USER_ID || ''
		)

		reset()
		// TODO: DESIGN SUCCESS MODAL OR REDIRECT TO SUCCESS PAGE
		history.push('/uspesna-rezervace')
	}

	const nameError = `${texts.pages.workshop.formName.error}`
	const emailError = `${texts.pages.workshop.formEmail.error}`
	const termsError = `${texts.pages.reservation.formTerms.error}`
	if (event) {
		return (
			<Wrapper>
				<TitlePage name={texts.pages.reservation.title} />
				<Form onSubmit={handleSubmit(onSubmit)}>
					{/*------------------NAME------------------*/}
					<FormItem>
						<FormLabel htmlFor="name">
							{texts.pages.workshop.formName.name}
						</FormLabel>
						<FormInput
							id="name"
							name="name"
							type="text"
							placeholder={
								texts.pages.workshop.formName.placeholder
							}
							ref={register({
								required: nameError,
								pattern: {
									value: /^[a-zA-Zá-žÁ-Ž\s]+$/i,
									message: `${texts.pages.workshop.formName.errorPattern}`,
								},
								maxLength: {
									value: 50,
									message: `${texts.pages.workshop.formName.errorMaxLength}`,
								},
								minLength: {
									value: 3,
									message: `${texts.pages.workshop.formName.errorMinLength}`,
								},
							})}
						/>
						{errors.name && (
							<ErrorMessage>{errors.name.message}</ErrorMessage>
						)}
					</FormItem>
					{/*------------------EMAIL------------------*/}
					<FormItem>
						<FormLabel htmlFor="email">
							{texts.pages.workshop.formEmail.name}
						</FormLabel>
						<FormInput
							id="email"
							name="email"
							type="email"
							placeholder={
								texts.pages.workshop.formEmail.placeholder
							}
							ref={register({
								required: emailError,
								pattern: {
									value: /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: `${texts.pages.workshop.formEmail.errorPattern}`,
								},
								maxLength: {
									value: 254,
									message: `${texts.pages.workshop.formEmail.errorMaxLength}`,
								},
							})}
						/>
						{errors.email && (
							<ErrorMessage>{errors.email.message}</ErrorMessage>
						)}
					</FormItem>
					{/*-----------------PHONE------------------*/}
					<FormItem>
						<FormLabel htmlFor="phone">
							{texts.pages.reservation.formPhone.name}
						</FormLabel>
						<FormInput
							id="phone"
							name="phone"
							type="number"
							placeholder={
								texts.pages.reservation.formPhone.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------COMPANY-NAME------------------*/}
					<FormItem>
						<FormLabel htmlFor="nameCompany">
							{texts.pages.reservation.formCompanyName.name}
						</FormLabel>
						<FormInput
							id="nameCompany"
							name="nameCompany"
							type="text"
							placeholder={
								texts.pages.reservation.formCompanyName
									.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------STREET------------------*/}
					<FormItem>
						<FormLabel htmlFor="street">
							{texts.pages.reservation.formStreet.name}
						</FormLabel>
						<FormInput
							id="street"
							name="street"
							type="text"
							placeholder={
								texts.pages.reservation.formStreet.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------CITY------------------*/}
					<FormItem>
						<FormLabel htmlFor="city">
							{texts.pages.reservation.formCity.name}
						</FormLabel>
						<FormInput
							id="city"
							name="city"
							type="text"
							placeholder={
								texts.pages.reservation.formCity.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------PSC------------------*/}
					<FormItem>
						<FormLabel htmlFor="postNumber">
							{texts.pages.reservation.formPostNumber.name}
						</FormLabel>
						<FormInput
							id="postNumber"
							name="postNumber"
							type="number"
							placeholder={
								texts.pages.reservation.formPostNumber
									.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------IDENTIFIER------------------*/}
					<FormItem>
						<FormLabel htmlFor="identifier">
							{texts.pages.reservation.formIdentifier.name}
						</FormLabel>
						<FormInput
							id="identifier"
							name="identifier"
							type="string"
							placeholder={
								texts.pages.reservation.formIdentifier
									.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------D-IDENTIFIER------------------*/}
					<FormItem>
						<FormLabel htmlFor="dIdentifier">
							{texts.pages.reservation.formDIdentifier.name}
						</FormLabel>
						<FormInput
							id="dIdentifier"
							name="dIdentifier"
							type="string"
							placeholder={
								texts.pages.reservation.formDIdentifier
									.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------PAY------------------*/}
					<FormItem>
						<FormLabel htmlFor="pay">
							{texts.pages.reservation.formPay.name}
						</FormLabel>
					</FormItem>
					{/*------------------MESSAGE------------------*/}
					<FormItem>
						<FormLabel htmlFor="message">
							{texts.pages.reservation.formMessage.name}
						</FormLabel>
						<FormTextArea
							id="message"
							name="message"
							placeholder={
								texts.pages.reservation.formMessage.placeholder
							}
							ref={register({
								required: false,
							})}
						/>
					</FormItem>
					{/*-----------------NEWS------------------*/}
					<FormCheckbox>
						<FormInput
							id="news"
							name="news"
							type="checkbox"
							ref={register({
								required: false,
							})}
						/>
						<FormLabel htmlFor="news">
							{texts.pages.reservation.formNews.name}
						</FormLabel>
					</FormCheckbox>
					{/*-----------------TERMS------------------*/}
					<FormCheckbox>
						<FormInput
							id="terms"
							name="terms"
							type="checkbox"
							ref={register({
								required: termsError,
							})}
						/>
						<FormLabel htmlFor="terms">
							{texts.pages.reservation.formTerms.name1}
							<StyledLink to="/podminky" target="_blank">
								{texts.pages.reservation.formTerms.name2}
							</StyledLink>
						</FormLabel>
						{errors.terms && (
							<ErrorMessage>{errors.terms.message}</ErrorMessage>
						)}
					</FormCheckbox>
					{/* TODO: Verify that it works well without token callback */}
					<ReCAPTCHA
						sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
						onChange={onChange}
						theme="light"
					/>
					{captchaError && (
						<ErrorMessage>
							{texts.pages.reservation.captcha.error}
						</ErrorMessage>
					)}
					<Button type="submit">
						{texts.pages.workshop.formButton}
					</Button>
				</Form>
			</Wrapper>
		)
	}
	return <p></p>
}

export default Reservation
