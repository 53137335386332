import styled, { css } from 'styled-components'
import { colors, fontFamily, fontWeight } from '../../styled/variables'

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 90%;
	border-radius: 15px;
`

export const FormItem = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
`

export const FormLabel = styled.label`
	padding: 5px;
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
`

export const FormInput = styled.input`
	width: 100%;
	border-radius: 10px;
	font-size: 1rem;
	color: ${colors.text.grey};
	padding: 12px 15px;
	outline: none;
	border: 1px solid ${colors.bg.green};

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
`

export const FormCheckbox = styled.div`
	display: flex;
	margin-bottom: 20px;
	align-items: center;

	input {
		width: 25px;
	}

	label {
		width: 100%;
	}
`

export const FormTextArea = styled.textarea`
	height: 200px;
	border-radius: 10px;
	font-size: 1rem;
	color: ${colors.text.grey};
	padding: 12px 15px;
	outline: none;
	font-family: ${fontFamily};
	border: 1px solid ${colors.bg.green};
`

export const basicButton = css`
	border: 1px solid ${colors.bg.white};
	border-radius: 10px;
	margin: auto;
	padding: 10px 55px;
	width: 200px;
	background-color: ${colors.bg.green};
	color: ${colors.text.white};
	font-weight: ${fontWeight.middleBold};
	letter-spacing: 1px;

	&:hover {
		color: ${colors.text.white};
		font-weight: ${fontWeight.medium};
		background-color: ${colors.text.green};
		filter: brightness(140%);
	}
`

export const Button = styled.button`
	${basicButton};
`

export const ErrorMessage = styled.p`
	color: #e40404;
	text-align: center;
`
