import styled from 'styled-components'
import {
	breakPoint,
	colors,
	heightHeader,
	logoText,
	subtitleText,
} from '../../../styled/variables'
import topPicture from '../../../assets/header-picture.png'
import topPictureLarge from '../../../assets/header-picture-large.png'
import topPictureTablet from '../../../assets/header-picture-tablet.png'
import topPicturePhone from '../../../assets/header-picture-phone.png'
import { Link } from 'react-router-dom'

export const Wrapper = styled.section`
	background-color: ${colors.bg.green};
	width: 100%;
	height: ${`${heightHeader}px`};
	position: relative;

	@media (max-width: ${breakPoint.phone}) {
		height: 114px;
	}
`

export const FixedHeader = styled.div`
	width: 100%;
	height: ${`${heightHeader}px`};
	position: fixed;
	top: 0;
	z-index: 1;

	@media (max-width: ${breakPoint.phone}) {
		height: 114px;
	}
`

export const Text = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;

	@media (max-width: ${breakPoint.tablet}) {
		width: 60%;
	}

	@media (max-width: ${breakPoint.phone}) {
		width: 90%;
	}
`

export const Logo = styled(Link)`
	font-size: 6rem;
	line-height: 6rem;
	font-family: ${logoText};
	color: ${colors.text.white};
	z-index: 1;

	@media (max-width: ${breakPoint.desktop}) {
		font-size: 5rem;
	}

	@media (max-width: ${breakPoint.tablet}) {
		font-size: 4.4rem;
		line-height: 5rem;
	}

	@media (max-width: ${breakPoint.ipad}) {
		font-size: 3.5rem;
		line-height: 4.5rem;
	}

	@media (max-width: ${breakPoint.phone}) {
		font-size: 3rem;
		line-height: 3.5rem;
	}
`

export const SubTitle = styled.span`
	color: ${colors.bg.grey};
	filter: brightness(90%);
	font-size: 1.5rem;
	letter-spacing: 1.5px;
	font-family: ${subtitleText};
	padding-left: 250px;

	@media (max-width: ${breakPoint.desktop}) {
		font-size: 1.8rem;
		padding-left: 150px;
	}

	@media (max-width: ${breakPoint.tablet}) {
		font-size: 1.6rem;
		padding-left: 120px;
	}

	@media (max-width: ${breakPoint.ipad}) {
		font-size: 1.2rem;
		padding-left: 70px;
	}

	@media (max-width: ${breakPoint.phone}) {
		font-size: 1.2rem;
		padding-left: 80px;
	}
`

export const Picture = styled.div`
	background-image: url(${topPictureLarge});
	width: 855px;
	height: ${`${heightHeader}px`};
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;

	@media (max-width: ${breakPoint.desktop}) {
		background-image: url(${topPicture});
		width: 618px;
		height: ${`${heightHeader}px`};
	}

	@media (max-width: ${breakPoint.tablet}) {
		background-image: url(${topPictureTablet});
		width: 461px;
		height: ${`${heightHeader}px`};
	}

	@media (max-width: ${breakPoint.phone}) {
		background-image: url(${topPicturePhone});
		width: 317px;
		height: 114px;
	}
`
