import styled from 'styled-components'
import { breakPoint } from '../../styled/variables'

export const Wrapper = styled.header`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 400px;

	@media (max-width: ${breakPoint.phone}) {
		height: 214px;
	}
`
