import React from 'react'
import { StyledLink } from '../../components/CookieConsent/styled'
import TitlePage from '../../components/TitlePage'
import * as texts from '../../texts/index.json'
import {
	Article,
	Wrapper,
	TitleArticle,
	ContentArticle,
	CircleList,
} from './styled'

const Terms: React.FC = () => (
	<Wrapper>
		<TitlePage name={texts.pages.terms.title} />
		<Article>
			<TitleArticle>1. Obecná ustanovení a definice</TitleArticle>
			<ContentArticle>
				Tyto všeobecné obchodní podmínky jsou platné pro společnost
				Školastik s.r.o., IČ: 27399656 se sídlem Oválná 1410, 293 01
				Mladá Boleslav, zapsané v obchodním rejstříku vedeném u
				Městského soudu v Praze pod spisovou značkou C 109882.
			</ContentArticle>
			<ContentArticle>
				Tyto obchodní podmínky upravují vzájemná práva a povinnosti mezi
				prodávajícím a kupujícím vzniklé na základě kupní smlouvy (dále
				jen „kupní smlouva“) uzavírané mezi prodávajícím a zájemcem o
				školení (dále jen „kupující“) prostřednictvím internetového
				obchodu Prodávajícího.
			</ContentArticle>
			<ContentArticle>
				Předmětem koupě se ve smyslu těchto všeobecných obchodních
				podmínek rozumí školení (dále jen „školení“). Obsah a rozsah
				školení, popř. doprovodných služeb, výše ceny včetně DPH
				vztahující se ke školení jsou uvedeny v přehledu kurzů na této
				webové stránce.
			</ContentArticle>
			<ContentArticle>
				Kupující odesláním objednávky potvrzuje, že se seznámil s
				obsahem všeobecných obchodních podmínek a jejich obsah považuje
				za jasný a srozumitelný. Tyto všeobecné obchodní podmínky jsou
				nedílnou součástí kupní smlouvy.
			</ContentArticle>
			<ContentArticle>
				Prodávající může znění všeobecných obchodních podmínek měnit či
				doplňovat.
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>2. Uzavření kupní smlouvy</TitleArticle>
			<ContentArticle>
				Rezervaci na školení je možné provést vyplněním registračního
				formuláře v přehledu kurzů. Slouží k rezervaci místa,
				občerstvení a materiálů ke školení.
			</ContentArticle>
			<ContentArticle>
				Odesláním objednávky kupující souhlasí se zpracováním a
				uchováním údajů uvedených v přihlášce prodávajícím.
			</ContentArticle>
			<ContentArticle>
				Objednávka odeslaná prodávajícímu je považována za návrh
				smlouvy. K uzavření kupní dochází okamžikem přijetí potvrzení
				objednávky kupujícím, které prodávající odešle na e-mail
				kupujícího uvedený v objednávce.
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>3. Způsob platby</TitleArticle>
			<ContentArticle>
				Objednávky lze hradit pouze bankovním převodem na základě
				faktury zaslané do e-mailu uvedeného v objednávce. Splatnost
				faktury je uvedena přímo na faktuře. V případě neuhrazení
				faktury v termínu splatnosti a plné obsazenosti kurzu, bude
				místo poskytnuto dalšímu zájemci o školení. Kupující bude o
				tomto informován e-mailem.
			</ContentArticle>
			<ContentArticle>
				Cena kurzu za osobu je uvedena u každého kurzu v přehledu kurzů.
				V ceně kurzu je zahrnuto školné, školící materiály, školící
				místo a drobné občerstvení. K ceně kurzů je účtováno DPH dle
				platných předpisů.
			</ContentArticle>
			<ContentArticle>
				Čas a místo školení bude upřesněno 5 dní před termínem školení.{' '}
			</ContentArticle>
			<ContentArticle>
				Společnost Školastik s.r.o. si vyhrazuje právo přesunout datum
				konání, čas konání i místo konání nebo kurz zrušit z důvodů,
				které nemůže ovlivnit (onemocnění lektora, přerušení dodávky
				proudu v místě konání apod.)
			</ContentArticle>
			<ContentArticle>
				Zároveň si společnost vyhrazuje právo zrušit kurz 5 pracovních
				dnů před zahájením kurzu v případě, že se nepřihlásil dostatečný
				počet účastníků.
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>4. Autorská práva</TitleArticle>
			<ContentArticle>
				Kupující bere na vědomí, že veškeré školicí materiály a podklady
				poskytnuté školicím střediskem v rámci konání školení které
				splňují znaky autorského díla ve smyslu zákona č. 121/2000 Sb. o
				právu autorském, o právech souvisejících s právem autorským a o
				změně některých zákonů (autorský zákon) jsou autorskoprávně
				chráněny. Žádný ze školicích materiálů a podkladů ani jeho část
				nesmí být bez předchozího písemného souhlasu školícího
				jakýmkoliv způsobem dále zpracováván, rozmnožován, rozšiřován
				nebo využíván k veřejným prezentacím, interním školením
				objednatelů a podobně.{' '}
			</ContentArticle>
			<ContentArticle>
				Kupující bere na vědomi, že za porušení autorských práv či
				jiných práv chráněných zákonem může být postižen dle platných
				právních předpisů.
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>5. Ukončení smlouvy</TitleArticle>
			<ContentArticle>
				Kupující má právo odstoupit od kupní smlouvy na školení ve lhůtě
				14 dnů a to písemně na e-mailovou adresu prihlaska@skolastik.cz
			</ContentArticle>
			<ContentArticle>
				Odstoupí-li kupující od smlouvy v uvedeném termínu, zašle mu
				prodávající přijaté peněžní prostředky na určený bankovní účet.{' '}
			</ContentArticle>
			<ContentArticle>
				Kupující nemá právo odstoupit od smlouvy v případě, že školení
				proběhlo před uplynutím 14 denní lhůty od uzavření kupní
				smlouvy, s čímž kupující souhlasí (par 1837 písm. a) NOZ).
			</ContentArticle>
			<ContentArticle>
				Dojde-li k odstoupení kupujícího od kupní smlouvy méně než 10
				dní před školením, zavazuje se kupující uhradit prodávajícímu
				smluvní pokutu (storno poplatek) ve výši 50% z ceny školení.{' '}
			</ContentArticle>
		</Article>
		<Article>
			<TitleArticle>
				6. Sdělení prodávajícího kupujícímu před uzavřením smlouvy
			</TitleArticle>
			<ContentArticle>
				Kupující odesláním objednávky potvrzuje, že ve smyslu § 1810 a
				následující NOZ obdržel od prodávajícího před uzavřením smlouvy
				jasné a srozumitelné informace požadované zákonem, a to:
			</ContentArticle>
			<CircleList>
				<li>
					totožnost prodávajícího, popř. telefonní číslo nebo adresu
					pro doručování elektronické pošty nebo jiný kontaktní údaj
				</li>
				<li>označení školení a popis jejich hlavních vlastností</li>
				<li>
					cenu školení, případně způsob jejího výpočtu včetně všech
					daní a poplatků
				</li>
				<li>způsob platby a způsob dodání plnění</li>
				<li>
					náklady na účast školení, a pokud tyto náklady nelze
					stanovit předem, údaj, že mohou být dodatečně účtovány
				</li>
				<li>
					údaje o právech vznikajících z vadného plnění, jakož i o
					právech ze záruky a další podmínky pro uplatňování těchto
					práv
				</li>
				<li>
					o podmínky, lhůtu a postupy pro uplatnění práva na
					odstoupení od smlouvy, lze-li ho využít, jakož i formulář na
					odstoupení od smlouvy
				</li>
			</CircleList>
		</Article>
		<Article>
			<TitleArticle>7. Závěrečná ustanovení</TitleArticle>
			<ContentArticle>
				Vztahy mezi oběma stranami kupní smlouvy se řídí právními
				předpisy České republiky. Případné spory bude rozhodovat
				příslušný soud. S případnou stížností se může kupující obrátit
				na orgán dohledu nebo státního dozoru.
			</ContentArticle>
			<ContentArticle>
				Tyto obchodní podmínky jsou zobrazeny na webových stránkách
				prodávajícího.
			</ContentArticle>
			<ContentArticle>
				Pro prodávajícího i kupujícího je závazné znění obchodních
				podmínek uvedené na webových stránkách prodávajícího v den, kdy
				kupující učiní objednávku.
			</ContentArticle>
			<ContentArticle>
				Pokud se prodávající s kupujícím nedohodnou v případě sporu na
				přijatelném kompromisu, může se spotřebitel obrátit na Českou
				obchodní inspekci s návrhem na zahájení mimosoudního řešení
				sporu. Náležitosti návrhu, popis průběhu jednání a další
				informace týkající se mimosoudního řešení sporu nalezne
				spotřebitel na internetových stránkách www.coi.cz.
			</ContentArticle>
		</Article>
		<Article>
			<ContentArticle>
				<StyledLink to="/cookies">GDPR podmínky</StyledLink>
			</ContentArticle>
		</Article>
	</Wrapper>
)

export default Terms
