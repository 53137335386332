import styled from 'styled-components'
import {
	breakPoint,
	colors,
	fontWeight,
	widthPages,
} from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 25px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}
`

export const Course = styled.p`
	width: 90%;
	font-size: 1.2rem;
	font-weight: ${fontWeight.middleBold};
	color: ${colors.text.grey};
	margin-bottom: 0;
`

export const NoCourse = styled.div`
	width: 90%;
	color: ${colors.text.green};
	margin: 0 0 20px 40px;
`
