import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { colors, fontWeight } from '../../styled/variables'

export const Wrapper = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px;
	border-radius: 10px;
	background-color: ${colors.bg.white};
`

export const Title = styled.span`
	width: 100%;
	display: flex;
	color: ${colors.text.green};
	font-weight: ${fontWeight.middleBold};
	padding: 0 10px;
`

const button = css`
	width: 42px;
	height: 40px;
	margin-left: 10px;
	border-radius: 50%;
	color: ${colors.bg.white};
	background-color: ${colors.bg.green};

	&:hover {
		background-color: ${colors.bg.green};
		filter: brightness(120%);
	}
`

export const EditButton = styled(Link)`
	${button};
	padding: 7px 6px 3px 8px;
`

export const DeleteButton = styled.button`
	${button};
	padding: 9px;
`
