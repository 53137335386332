import React from 'react'
import avatar from '../../assets/avatar.png'
import { Link } from 'react-router-dom'
import * as texts from '../../texts/index.json'
import {
	Avatar,
	Buttons,
	Clock,
	DateAndTime,
	Dating,
	Button,
	Person,
	Picture,
	TextInfo,
	Title,
	Wrapper,
	Line,
} from './styled'

type Props = {
	to: number
	from: number
	name: string
	speaker: string
	id: string
	enableReservation: boolean
}

const ItemWorkshop: React.FC<Props> = ({
	to,
	from,
	name,
	speaker,
	id,
	enableReservation,
}) => {
	const dateFrom = new Date(from)
	const dateTo = new Date(to)

	return (
		<Wrapper>
			<DateAndTime>
				<Dating>{`${dateFrom.getUTCDate()}. ${
					dateFrom.getUTCMonth() + 1
				}. ${dateFrom.getFullYear()}`}</Dating>
				<Clock>{`${dateFrom.getHours()}:${dateFrom
					.getMinutes()
					.toString()
					.padStart(
						2,
						'0'
					)} - ${dateTo.getHours()}:${dateTo
					.getMinutes()
					.toString()
					.padStart(2, '0')}`}</Clock>
			</DateAndTime>
			<Line />
			<Avatar>
				<Picture src={avatar} alt={texts.pages.workshop.altPicture} />
			</Avatar>
			<TextInfo>
				<Link to={`/detail-kurzu/${id}`}>
					<Title>{name}</Title>
				</Link>
				<Person>{speaker}</Person>
				<Buttons>
					{enableReservation && (
						<Button to={`/rezervace/${id}`}>
							{texts.pages.workshop.button1}
						</Button>
					)}

					<Button to={`/detail-kurzu/${id}`}>
						{texts.pages.workshop.button2}
					</Button>
				</Buttons>
			</TextInfo>
		</Wrapper>
	)
}

export default ItemWorkshop
