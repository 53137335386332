import React, { useEffect, useState } from 'react'
import { heightHeader } from '../../../styled/variables'
import * as texts from '../../../texts/index.json'
import { ItemOfList, List, StyledLink, Wrapper } from './styled'

const Nav: React.FC = () => {
	const [sticky, setSticky] = useState(false)

	useEffect(() => {
		window.addEventListener('scroll', () =>
			window.scrollY > heightHeader ? setSticky(true) : setSticky(false)
		)
	}, [])

	return (
		<Wrapper $sticky={sticky}>
			<List>
				<ItemOfList>
					<StyledLink $sticky={sticky} to="/">
						{texts.header.navLink1}
					</StyledLink>
				</ItemOfList>
				<ItemOfList>
					<StyledLink $sticky={sticky} to="/nabidka-kurzu">
						{texts.header.navLink2}
					</StyledLink>
				</ItemOfList>
				<ItemOfList>
					<StyledLink $sticky={sticky} to="/kontakt">
						{texts.header.navLink3}
					</StyledLink>
				</ItemOfList>
			</List>
		</Wrapper>
	)
}

export default Nav
