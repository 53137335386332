import styled from 'styled-components'
import { breakPoint, widthPages } from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}
`
