import React from 'react'
import { DeleteButton, EditButton, Title, Wrapper } from './styled'
import { Pencil, DeleteBin7 } from '@styled-icons/remix-fill'

type Props = {
	name: string
	eventId: string
	deleteAction: (idEvent: string) => void
}

const EditCourse: React.FC<Props> = ({ name, eventId, deleteAction }) => (
	<Wrapper>
		<Title>{name}</Title>
		<EditButton to={`/uprava-skoleni/${eventId}`}>
			<Pencil />
		</EditButton>
		<DeleteButton>
			<DeleteBin7 onClick={() => deleteAction(eventId)} />
		</DeleteButton>
	</Wrapper>
)

export default EditCourse
