import React, { useEffect, useState } from 'react'
import picture from '../../assets/avatar.png'
import * as texts from '../../texts/index.json'
import { Event, container } from '../../services/firebase'
import { Time, CalendarTodo } from '@styled-icons/remix-fill'
import {
	Avatar,
	ContentCourse,
	DateInfo,
	Description,
	DivInfo,
	Icon,
	LinkButton,
	Name,
	Picture,
	Place,
	Price,
	Teacher,
	TeacherText,
	Title,
	TitleCourse,
	Wrapper,
} from './styled'
import { useParams } from 'react-router'

const DetailCourse: React.FC = () => {
	const [data, setdata] = useState<Event | null>()

	const { id } = useParams<{ id: string }>()

	useEffect(() => {
		container.collections.events.getEvent(id).then((info) => setdata(info))
	}, [id])

	if (data) {
		const dateFrom = new Date(data.from)
		const dateTo = new Date(data.to)

		const actualTime = new Date().getTime()

		const isReservable = dateFrom.getTime() > actualTime

		return (
			<Wrapper>
				<TitleCourse>{data.name}</TitleCourse>
				<DateInfo>
					<DivInfo>
						<Icon>
							<CalendarTodo />
						</Icon>
						{`${dateFrom.getUTCDate()}. ${
							dateFrom.getUTCMonth() + 1
						}. ${dateFrom.getFullYear()}`}
					</DivInfo>
					<DivInfo>
						<Icon>
							<Time />
						</Icon>
						{`${dateFrom.getHours()}:${dateFrom
							.getMinutes()
							.toString()
							.padStart(
								2,
								'0'
							)} - ${dateTo.getHours()}:${dateTo
							.getMinutes()
							.toString()
							.padStart(2, '0')}`}
					</DivInfo>
					{isReservable ? (
						<LinkButton to={`/rezervace/${id}`}>
							{texts.pages.detailCourse.button}
						</LinkButton>
					) : (
						<div></div>
					)}
				</DateInfo>
				<Place>
					{texts.pages.detailCourse.place}
					{`${data.place}`}
				</Place>
				<Title>{texts.pages.detailCourse.contentCourse}</Title>
				<ContentCourse>{`${data.description}`}</ContentCourse>
				<Title>{texts.pages.detailCourse.speaker}</Title>
				<Teacher>
					<Picture>
						<Avatar
							src={picture}
							alt={texts.pages.detailCourse.photo}
						/>
					</Picture>
					<Description>
						<Name>{data.speakerName}</Name>
						<TeacherText>
							{`${data.speakerDescription}`}
						</TeacherText>
					</Description>
				</Teacher>
				<Price>
					{texts.pages.detailCourse.price}
					{data.price}
				</Price>
				{isReservable && (
					<LinkButton to={`/rezervace/${id}`}>
						{texts.pages.detailCourse.button}
					</LinkButton>
				)}
			</Wrapper>
		)
	}
	return <p></p>
}

export default DetailCourse
