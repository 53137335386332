import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
	breakPoint,
	colors,
	fontWeight,
	widthPages,
} from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 70px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 100%;
	}
`

export const Courses = styled.section`
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	@media (max-width: ${breakPoint.ipad}) {
		width: 90%;
	}
`

export const PeriodCourse = styled.p`
	width: 100%;
	font-size: 1.2rem;
	font-weight: ${fontWeight.bold};
	color: ${colors.text.grey};
	margin: 40px 0 0 0;
`

export const AddCourse = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 80%;

	a:first-child {
		color: ${colors.text.green};

		&:hover {
			filter: brightness(120%);
		}
	}

	@media (max-width: ${breakPoint.ipad}) {
		width: 90%;
	}
`

export const ButtonPlus = styled(Link)`
	background-color: ${colors.bg.green};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	font-size: 2.5rem;
	color: ${colors.text.white};
	border: 2px solid ${colors.bg.green};
	margin: 0 20px;

	&:hover {
		background-color: ${colors.bg.green};
		filter: brightness(120%);
		color: ${colors.text.white};
	}
`

export const LogoutText = styled.div`
	color: ${colors.text.green};
	width: 100%;
	height: 600px;
	display: flex;
	justify-content: center;
	padding-top: 100px;
`
