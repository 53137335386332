import React from 'react'
import * as texts from '../../../texts/index.json'
import { Logo, Picture, Wrapper, Text, SubTitle, FixedHeader } from './styled'

const TopBar: React.FC = () => (
	<Wrapper>
		<FixedHeader>
			<Text>
				<Logo to="/">{texts.header.logo}</Logo>
				<SubTitle>{texts.header.subtitle}</SubTitle>
			</Text>
			<Picture />
		</FixedHeader>
	</Wrapper>
)

export default TopBar
