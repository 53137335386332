import React from 'react'
import {
	Address,
	Article,
	Button,
	Company,
	Container1Columns,
	Container2Columns,
	Headline,
	Info,
	Line,
	Mapa,
	Meeting,
	Name,
	Paper,
	Paper2,
	Picture,
	Title,
	Wrapper,
} from './styled'
import picture from '../../assets/home-picture.jpg'
import meeting from '../../assets/meeting.jpg'
import * as texts from '../../texts/index.json'

const HomePage: React.FC = () => (
	<Wrapper>
		<Container2Columns>
			<Picture src={picture} alt={texts.pages.home.altPicture} />
			<Article>
				<Headline>{texts.pages.home.headline}</Headline>
				<Paper>{texts.pages.home.article1}</Paper>
			</Article>
		</Container2Columns>
		<Title>
			<Name>{texts.pages.home.title1}</Name>
			<Line />
		</Title>
		<Container1Columns>
			<Paper2>{texts.pages.home.article2}</Paper2>
			<Button to="/nabidka-kurzu">{texts.pages.home.button}</Button>
		</Container1Columns>
		<Title>
			<Line />
			<Name>{texts.pages.home.title2}</Name>
		</Title>
		<Container2Columns>
			<Info>
				<Company>{texts.general.company}</Company>
				<Address>
					{texts.general.streetAddress}
					<br />
					{texts.general.cityAddress}
				</Address>
				<Address>
					<a href="tel:+420735729551">{texts.general.phoneNumber}</a>
					<br />
					<a href="mailto:prihlaska@skolastik.cz">
						{texts.general.email}
					</a>
				</Address>
			</Info>
			<Mapa>
				<Meeting src={meeting} alt={texts.pages.home.altMeeting} />
			</Mapa>
		</Container2Columns>
	</Wrapper>
)

export default HomePage
