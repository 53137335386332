import styled from 'styled-components'
import {
	breakPoint,
	colors,
	fontWeight,
	widthPages,
} from '../../styled/variables'

export const Wrapper = styled.section`
	width: ${widthPages};
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 40px;

	@media (max-width: ${breakPoint.phone}) {
		width: 100%;
	}
`

export const Article = styled.article`
	width: 90%;
	margin-bottom: 30px;
`

export const TitleArticle = styled.p`
	color: ${colors.text.black};
	font-weight: ${fontWeight.middleBold};
	padding-left: 30px;
`

export const ContentArticle = styled.p`
	color: ${colors.text.grey};
`

export const CircleList = styled.ul`
	list-style-type: circle;
	padding-left: 70px;
	line-height: 1.6rem;
	color: ${colors.text.grey};
`

export const NumberList = styled.ol`
	padding-left: 70px;
	line-height: 1.6rem;
	color: ${colors.text.grey};

	ul {
		padding-left: 30px;
	}
`

export const LastText = styled.p`
	text-align: center;
	margin: 0;
	font-size: 0.8rem;
	color: ${colors.text.grey};
`
