import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { breakPoint, colors, fontWeight } from '../../styled/variables'

export const Wrapper = styled.section`
	background-color: ${colors.bg.white};
	width: 90%;
	height: 100%;
	display: flex;
	border-radius: 15px;
	gap: 25px;

	&:hover {
		box-shadow: 2px 2px 10px ${colors.text.grey};
	}

	@media (max-width: ${breakPoint.desktop}) {
		gap: 15px;
	}

	@media (max-width: ${breakPoint.tablet}) {
		display: grid;
		grid-template-columns: 43% 10% 43%;
	}

	@media (max-width: ${breakPoint.phone}) {
		display: flex;
		flex-direction: column;
	}
`

export const DateAndTime = styled.div`
	display: flex;
	flex-direction: column;
	width: 20%;
	height: 100%;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakPoint.desktop}) {
		width: 15%;
	}

	@media (max-width: ${breakPoint.tablet}) {
		width: 100%;
	}

	@media (max-width: ${breakPoint.phone}) {
		height: 70px;
		flex-direction: row;
		justify-content: space-around;
	}
`

export const Dating = styled.span`
	margin-bottom: 20px;
	color: ${colors.text.green};
	font-weight: ${fontWeight.bold};

	@media (max-width: ${breakPoint.phone}) {
		margin-bottom: 0;
	}
`

export const Clock = styled.span`
	color: ${colors.text.grey};
`

export const Line = styled.div`
	background-color: ${colors.bg.grey};
	width: 2.5px;
	height: 80%;
	align-self: center;
	justify-self: center;

	@media (max-width: ${breakPoint.phone}) {
		display: none;
	}
`

export const Avatar = styled.div`
	width: 19%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${breakPoint.tablet}) {
		width: 70%;
		justify-self: center;
	}

	@media (max-width: ${breakPoint.phone}) {
		margin: auto;
		width: 50%;
	}
`

export const Picture = styled.img`
	background-color: ${colors.bg.white};
	width: 60%;
	border-radius: 50%;
	border: 2px solid ${colors.bg.grey};
	margin: 20px;

	@media (max-width: ${breakPoint.desktop}) {
		width: 70%;
	}
`

export const TextInfo = styled.article`
	width: 61%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: ${breakPoint.tablet}) {
		grid-column: 1 / 4;
		justify-self: center;
		width: 93%;
	}

	@media (max-width: ${breakPoint.phone}) {
		margin: auto;
		width: 90%;
	}
`

export const Title = styled.h3`
	width: 95%;
	color: ${colors.text.black};
	font-weight: ${fontWeight.middleBold};
	font-size: 1.3rem;
	margin: 20px 0 10px 0;
	padding: 0;

	@media (max-width: ${breakPoint.phone}) {
		text-align: center;
		width: 100%;
	}
`

export const Person = styled.p`
	width: 95%;
	margin: 0 0 10px 0;
	padding: 0;
	color: ${colors.text.grey};

	@media (max-width: ${breakPoint.phone}) {
		text-align: center;
		width: 100%;
	}
`

export const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;

	@media (max-width: ${breakPoint.phone}) {
		justify-content: center;
	}
`

export const Button = styled(Link)`
	margin-right: 15px;
	padding: 7px 15px;
	border-radius: 10px;
	color: ${colors.text.white};
	background-color: ${colors.bg.green};
	font-weight: ${fontWeight.medium};
	font-size: 0.8rem;

	&:hover {
		filter: brightness(130%);
	}
`
