import React, { useEffect, useState } from 'react'
import * as texts from '../../texts/index.json'
import { useUser } from '../../features/User'
import TitlePage from '../../components/TitlePage'
import {
	AddCourse,
	ButtonPlus,
	Courses,
	LogoutText,
	PeriodCourse,
	Wrapper,
} from './styled'
import EditCourse from '../../components/EditCourse'
import { container, Event } from '../../services/firebase'
import { Link } from 'react-router-dom'
import { NoCourse } from '../Workshop/styled'

const Administration: React.FC = () => {
	const { isLoggedIn } = useUser()

	const [events, setEvents] = useState<Event[]>([])

	useEffect(() => {
		container.collections.events
			.getAllEvents()
			.then((events) => setEvents(events))
	}, [])

	const deleteEvent = (eventId: Event['id']) => {
		container.collections.events.deleteEvent(eventId).then(() => {
			container.collections.events
				.getAllEvents()
				.then((events) => setEvents(events))
		})
	}

	const actualTime = new Date().getTime()
	const lastEvents = events
		.filter((event) => event.from < actualTime)
		.sort((a, b) => (a.from > b.from ? -1 : 1))
	const prepareEvents = events
		.filter((event) => event.from > actualTime)
		.sort((a, b) => (a.from < b.from ? -1 : 1))

	return (
		<Wrapper>
			{isLoggedIn ? (
				<>
					<TitlePage name={texts.pages.administration.title} />
					<AddCourse>
						<Link to="/nove-skoleni">
							{texts.pages.administration.addCourse}
						</Link>
						<ButtonPlus to="/nove-skoleni">+</ButtonPlus>
					</AddCourse>
					<Courses>
						<PeriodCourse>
							{texts.pages.workshop.upcomingCourses}
						</PeriodCourse>
						{prepareEvents.length === 0 ? (
							<NoCourse>{texts.pages.workshop.noCourse}</NoCourse>
						) : (
							prepareEvents.map((event, index) => (
								<EditCourse
									key={index}
									name={event.name}
									deleteAction={deleteEvent}
									eventId={event.id}
								/>
							))
						)}
					</Courses>
					<Courses>
						<PeriodCourse>
							{texts.pages.workshop.pastCourses}
						</PeriodCourse>
						{lastEvents.map((event, index) => (
							<EditCourse
								key={index}
								name={event.name}
								deleteAction={deleteEvent}
								eventId={event.id}
							/>
						))}
					</Courses>
				</>
			) : (
				<>
					<LogoutText>{texts.pages.administration.logout}</LogoutText>
				</>
			)}
		</Wrapper>
	)
}

export default Administration
