import React from 'react'
import CookieConsent from 'react-cookie-consent'
import * as texts from '../../texts/index.json'
import { StyledLink } from './styled'

const Cookie: React.FC = () => {
	return (
		<CookieConsent
			location="bottom"
			buttonText={texts.cookie.agreeButton}
			cookieName="cookieExpiration"
			style={{ background: '#2B373B' }}
			buttonStyle={{
				color: 'white',
				fontSize: '18px',
				backgroundColor: '#017723',
				borderRadius: '8px',
			}}
			expires={150}
		>
			{texts.cookie.text}
			<StyledLink to="/cookies">{texts.cookie.link}</StyledLink>
		</CookieConsent>
	)
}

export default Cookie
